export const generateLead = async ({ name, email }: { name: string, email: string }) => {
  const data = await fetch(
    `${process.env.REACT_APP_BACKEND}/salesforce/generate-lead`,
    {
      method: "POST",
      body: JSON.stringify({
        lead: {
          name,
          email,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => res.json());
  console.log(data);
};

export const trackLead = async ({ message, email }: { message: string, email: string }) => {
  const data = await fetch(
    `${process.env.REACT_APP_BACKEND}/salesforce/track-lead`,
    {
      method: "POST",
      body: JSON.stringify({
        message,
        email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => res.json());
  console.log(data);
};
