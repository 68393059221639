
import React, { useContext, Suspense, lazy } from "react";
import tw from "twin.macro";

import Loader from "../components/Widgets/Loader";

import { GlobalContext } from "../context/global";

const SceneWrapper = tw.div`absolute w-full h-full z-10 overflow-hidden flex justify-center text-center`;

const ThreeDScene = lazy(() => import("./ThreeDScene"));
const AtriumScene = lazy(() => import("./AtriumScene"));
const BoothScene = lazy(() => import("./BoothScene"));
const SymposiumScene = lazy(() => import("./SymposiumScene"));

const Scenes: React.FC = () => {
  const {
    state: {
      app: { scene },
    },
  } = useContext(GlobalContext);

  return (
    <SceneWrapper>
      {scene === "atrium" && (
        <Suspense fallback={<Loader text="Atrium" />}>
          <ThreeDScene Scene={AtriumScene} />
        </Suspense>
      )}
      {scene === "booth" && (
        <Suspense fallback={<Loader text="Booth" />}>
          <ThreeDScene Scene={BoothScene} />
        </Suspense>
      )}
      {scene === "symposium" && (
        <Suspense fallback={<Loader text="Symposium" />}>
          <ThreeDScene Scene={SymposiumScene} />
        </Suspense>
      )}
    </SceneWrapper>
  );
}

export default Scenes;
