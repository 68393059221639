import React from "react";

import tw from "twin.macro";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { isApple } from "../../utils/mobileDetector";

const OrientationWarning = styled.div([
  tw`fixed w-full h-full z-50 text-center hidden m-auto`,
  css`
    background: rgba(22, 22, 22, 0.9);
    @media only screen and (max-device-width: 640px) and (orientation: portrait) {
      display: block;
    }
  `,
]);

const WarningWrap = styled.div([
  tw`text-white absolute px-4 top-0 left-0 bottom-0 right-0 m-auto w-full h-full`,
  css`
    height: 50vh;
  `,
]);

const WarningText = tw.h2`text-3xl font-semibold`;

const AppleWarning = tw.p`text-white text-xl mt-4`;

type WarningProps = {
  text: string;
};

const PortraitOverlay: React.FC<WarningProps> = ({ text }) => {
  return (
    <OrientationWarning className="portrait-warning">
      <WarningWrap>
        <WarningText>
          <motion.p
            style={{ fontSize: "4rem" }}
            animate={{ rotate: 90 }}
            transition={{
              duration: 1,
              delay: 1,
              ease: "easeOut",
            }}
          >
            <FontAwesomeIcon icon="mobile-alt" />
          </motion.p>
          {text}
        </WarningText>
        {isApple && (
          <AppleWarning>
            For a better experience please add Virtual Congress to your home
            screen
          </AppleWarning>
        )}
      </WarningWrap>
    </OrientationWarning>
  );
};

export default PortraitOverlay;
