export interface UserState {
  firstName: string,
  email: string,
  authorised: boolean,
  superuser: boolean,
  error: Error | null, 
}

export interface UserAction extends UserState {
  type: string;
}

const isAuthorised = (): boolean => {
  if (localStorage.getItem("authorised")) {
    return JSON.parse(localStorage.getItem("authorised")).value === "true"
      ? true
      : false;
  }
};

const isSuperUser = (): boolean => {
  if (localStorage.getItem("superuser")) {
    return JSON.parse(localStorage.getItem("superuser")).value === "true"
      ? true
      : false;
  } else {
  }
};

export const initialState: UserState = {
  firstName: localStorage.getItem("firstName")
    ? JSON.parse(localStorage.getItem("firstName")).value
    : "",
  email: localStorage.getItem("email")
    ? JSON.parse(localStorage.getItem("email")).value
    : "",
  authorised: isAuthorised(),
  superuser: isSuperUser(),
  error: null,
};

export const reducer = function (state: UserState, action: UserAction) : UserState {
  switch (action.type) {
    case "USER_AUTHORISE":
      return {
        ...state,
        authorised: true,
      };
    case "USER_UPDATE_FIRSTNAME":
      return {
        ...state,
        firstName: action.firstName,
        email: action.email,
      };
    case "USER_ERROR":
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
