import { RoomUser, RoomMessage } from "../@types/types";

export const join = (
  socket: SocketIOClient.Socket,
  { firstName, room }: RoomUser
) => {
  socket.emit("welcome", { firstName, room });
};

export const sendMessage = function (
  socket: SocketIOClient.Socket,
  payload: RoomMessage
) {
  console.log("EMITTING MESSAGE", payload);
  socket.emit("message", payload);
};
