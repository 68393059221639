import React, { createContext, useReducer } from "react";

import {
  UserState,
  UserAction,
  initialState as userInitialState,
  reducer as userReducer,
} from "./user";

import { 
  AppState,
  AppAction,
  initialState as appInitialState,
  reducer as appReducer 
} from "./app";

import {
  SocketState,
  SocketAction,
  initialState as socketInitialState,
  reducer as socketReducer,
} from "./socket";

import {
  ChatState,
  ChatAction,
  initialState as chatInitialState,
  reducer as chatReducer,
} from "./chat";

import {
  ChatbotState,
  ChatbotAction,
  initialState as chatbotInitialState,
  reducer as chatbotReducer,
} from "./chatbot";

interface GlobalState {
  user: UserState,
  app: AppState,
  socket: SocketState,
  chat: ChatState, 
  chatbot: ChatbotState,
}

type GlobalAction = UserAction & AppAction &  SocketAction &  ChatAction &  ChatbotAction;

const initialState: GlobalState = {
  user: userInitialState,
  app: appInitialState,
  socket: socketInitialState,
  chat: chatInitialState,
  chatbot: chatbotInitialState,
};

const reducer = function (state: GlobalState, action: GlobalAction): GlobalState {
  const newState: GlobalState = {
    user: userReducer(state.user, action),
    app: appReducer(state.app, action),
    socket: socketReducer(state.socket, action),
    chat: chatReducer(state.chat, action),
    chatbot: chatbotReducer(state.chatbot, action),
  };
  return newState;
};

export const GlobalContext = createContext({
  state: initialState,
  dispatch: null,
});

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};
