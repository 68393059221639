import React, { useContext, Suspense, lazy, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import screenfull, { Screenfull } from "screenfull";
import tw from "twin.macro";

import Layout from "./components/Layout";
import Loader from "./components/Widgets/Loader";
import Scenes from "./scenes/";
import FullScreenBtn from "./components/Widgets/FullScreenBtn";
import PortraitOverlay from "./components/Widgets/PortraitOverlay";
import isMobile from "./utils/mobileDetector";

import { connect } from "./helpers/Connect";
import { join } from "./helpers/ChatClient";
import { trackLead } from "./helpers/Salesforce";

import { GlobalContext } from "./context/global";

const Welcome = lazy(() => import("./components/Welcome"));
const Chat = lazy(() => import("./components/Chat"));
const VideoChat = lazy(() => import("./components/VideoChat"));
const Chatbot = lazy(() => import("./components/Chatbot"));
const Downloads = lazy(() => import("./components/Downloads"));
const Agenda = lazy(() => import("./components/Agenda"));
const VideoDemo = lazy(() => import("./components/VideoDemo"));
const SymposiumVideo = lazy(() => import("./components/SymposiumVideo"));

const BtnFS = tw.div`fixed right-auto top-0 left-auto w-48 text-center z-40`;

const App: React.FC = () => {
  const {
    state: {
      user: { authorised, firstName, email },
      socket: { socket, congressRoom },
      app: {
        ui: {
          chat,
          meeting,
          chatbot,
          downloads,
          agenda,
          videoDemo,
          symposiumVideo,
        },
      },
    },
    dispatch,
  } = useContext(GlobalContext);

  const [fullS, setFullS] = useState<boolean>(false);

  useEffect(() => {
    if (authorised) {
      connect({ socket, dispatch }).then(() => {
        join(socket, { firstName, room: congressRoom });
      });
      trackLead({ message: `${firstName} joined the congress`, email });
    }
    if (screenfull.isEnabled) {
      screenfull.on("change", () => {
        const { isFullscreen } = screenfull as Screenfull;
        setFullS(isFullscreen);
      });
    }
  }, [authorised, socket, firstName, congressRoom, dispatch, email]);

  const handleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  };

  return (
    <Layout>
      <PortraitOverlay text="Please turn your device in landscape mode" />
      {!isMobile && (
        <BtnFS style={{ top: "-33px" }}>
          <motion.div whileHover={{ y: 28 }}>
            <FullScreenBtn
              text={`${fullS ? "exit full screen" : "enter full screen"}`}
              clickEvent={handleFullScreen}
            />
          </motion.div>
        </BtnFS>
      )}
      <AnimatePresence>
        {!authorised && (
          <motion.div
            initial={{ opacity: 0, y: -200 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -200 }}
          >
            <Suspense fallback={<Loader text="Welcome" />}>
              <Welcome />
            </Suspense>
          </motion.div>
        )}
      </AnimatePresence>

      {authorised && socket && (
        <>
          <Scenes />

          {chat.include && (
            <Suspense fallback={<Loader text="Chat" />}>
              <Chat />
            </Suspense>
          )}

          {meeting.include && (
            <Suspense fallback={<Loader text="Video Chat" />}>
              <VideoChat />
            </Suspense>
          )}

          {chatbot.include && (
            <Suspense fallback={<Loader text="Chatbot" />}>
              <Chatbot />
            </Suspense>
          )}

          {downloads.include && (
            <Suspense fallback={<Loader text="Downloads" />}>
              <Downloads />
            </Suspense>
          )}

          {agenda.include && (
            <Suspense fallback={<Loader text="Agenda" />}>
              <Agenda />
            </Suspense>
          )}

          {videoDemo.include && (
            <Suspense fallback={<Loader text="Video Demo" />}>
              <VideoDemo />
            </Suspense>
          )}

          {symposiumVideo.include && (
            <Suspense fallback={<Loader text="Symposium Video" />}>
              <SymposiumVideo />
            </Suspense>
          )}
        </>
      )}
    </Layout>
  );
};

export default App;
