import { ChatbotMessage } from '../@types/types';

export interface ChatbotState {
  chatHistory: ChatbotMessage[];
}

export interface ChatbotAction extends ChatbotState {
  type: string
}

export const initialState: ChatbotState = {
  chatHistory: [],
};

export const reducer = function (state: ChatbotState, action: ChatbotAction): ChatbotState {
  switch (action.type) {
    case "CHATBOT_ADD_MESSAGE":
      return {
        ...state,
        chatHistory: action.chatHistory,
      };
    case "CHATBOT_CLEAR_MESSAGE":
      return {
        ...state,
        chatHistory: [],
      };
    default:
      return state;
  }
};
