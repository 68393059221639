import configureUi from "../config/Components";
import { UiElements } from "../@types/types";

export interface AppState {
  ui: UiElements;
  scene: string;
}

export interface AppAction extends AppState {
  type: string;
  component: string;
}

export const initialState: AppState = {
  ui: configureUi,
  scene: "atrium",
};

export const reducer = function (state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case "APP_TOGGLE":
      console.log("VISIBILITY", state.ui[action.component].visible);
      state.ui[action.component].visible = !state.ui[action.component].visible;
      return state;
    case "APP_UPDATE_UI":
      return state;
    case "APP_TOGGLE_UI_COMPONENT":
      state.ui.chat.visible = false;
      state.ui.chatbot.visible = false;
      state.ui.meeting.visible = false;
      state.ui.downloads.visible = false;
      state.ui.agenda.visible = false;
      state.ui.videoDemo.visible = false;
      state.ui.symposiumVideo.visible = false;
      switch (action.component) {
        case "chat":
          state.ui.chat.visible = true;
          break;
        case "chatbot":
          state.ui.chatbot.visible = true;
          break;
        case "meeting":
          state.ui.meeting.visible = true;
          break;
        case "downloads":
          state.ui.downloads.visible = true;
          break;
        case "agenda":
          state.ui.agenda.visible = true;
          break;
        case "videoDemo":
          state.ui.videoDemo.visible = false;
          break;
        case "symposiumVideo":
          state.ui.symposiumVideo.visible = true;
          break;
      }
      return state;
    case "APP_UPDATE_SCENE":
      return {
        ...state,
        scene: action.scene,
      };
    default:
      return state;
  }
};
