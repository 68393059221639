import { v1 as uuidv1 } from "uuid";

const configureUi = {
  chat: {
    id: uuidv1(),
    include: true,
    initialised: false,
    visible: false,
  },
  chatbot: {
    id: uuidv1(),
    include: true,
    initialised: false,
    visible: false,
  },
  meeting: {
    id: uuidv1(),
    include: true,
    initialised: false,
    visible: false,
  },
  downloads: {
    id: uuidv1(),
    include: true,
    initialised: false,
    visible: false,
  },
  agenda: {
    id: uuidv1(),
    include: true,
    initialised: false,
    visible: false,
  },
  symposiumVideo: {
    id: uuidv1(),
    include: true,
    initialised: false,
    visible: false,
  },
  videoDemo: {
    id: uuidv1(),
    include: true,
    initialised: false,
    visible: true,
  },
};

export default configureUi;
