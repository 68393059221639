import io from "socket.io-client";

interface ConnectParams {
  socket: SocketIOClient.Socket;
  dispatch: any;
}

export const connect = async ({ socket, dispatch }: ConnectParams): Promise<SocketIOClient.Socket> => {
  return new Promise(async (resolve) => {
    if (socket === null) {
      dispatch({
        type: 'LOADING',
      });

      try {
        const connection = await ioConnect();
        
        dispatch({
          type: 'SOCKET_CONNECTED',
          socket: connection,
        });
      }
      catch(error) {
        dispatch({
          type: 'USER_ERROR',
          error,
        });
      }
    }
    else {
      resolve(socket);
    }
  });
};

export const ioConnect = async (): Promise<SocketIOClient.Socket> => {
  return new Promise((resolve, reject) => {
    try {
      const connection = io(process.env.REACT_APP_BACKEND);

      resolve(connection);
    }
    catch (error) {
      reject('Failed to connect to server, please try again later.');
    }
  });
};