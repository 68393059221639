import React from "react";
import tw from "twin.macro";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/core";
import isMobile from "../../utils/mobileDetector";

import Navigation from "../Navigation";
import MobileNav from "../MobileNav";
//import logo from "../../assets/logo.svg";

const Content = styled.div([
  tw`absolute z-10 w-screen h-full flex justify-center items-center text-gray-700 p-5`,
  css`
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    button {
      outline: 0;
    }
  `,
]);

interface Props {
  children: JSX.Element[] | JSX.Element;
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <Content>
      {children}
      {!isMobile ? <Navigation /> : <MobileNav />}
    </Content>
  );
};

export default Layout;
