const isMobile =
  navigator.userAgent.indexOf("Mobile") !== -1 ||
  navigator.userAgent.indexOf("iPhone") !== -1 ||
  navigator.userAgent.indexOf("iPad") !== -1 ||
  navigator.userAgent.indexOf("Android") !== -1 ||
  navigator.userAgent.indexOf("Windows Phone") !== -1;

export const isApple =
  navigator.userAgent.indexOf("iPhone") !== -1 ||
  navigator.userAgent.indexOf("iPad") !== -1;

export default isMobile;
