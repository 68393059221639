import React from "react";

interface Props {
  text: string;
}

const Loader: React.FC<Props> = ({text}) => {
  return (
    <>
      {/*<H1>LOADING</H1>
      <Icon icon="circle-notch" size="text-xl" />*/}
    </>
  );
};

export default Loader;