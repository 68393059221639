import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import tw from "twin.macro";
import styled from "@emotion/styled/macro";
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import Icon from "../Widgets/Icon";


interface Props {
  active: boolean;
  clickEvent: () => void;
  icon: IconProp;
  size: string;
  text: string;
}

const NavigationButton: React.FC<Props> = ({ active, clickEvent, icon, size, text }) => {
  const IconWrapper = styled.div([
    tw`w-8 flex justify-center`,
    active ? tw`mr-2` : "",
  ]);
  const Button = styled.div([
    tw`h-12 px-3 bg-white shadow flex items-center rounded hover:text-white hover:bg-color-red-600 hover:cursor-pointer`,
  ]);

  return (
    <Button onClick={clickEvent}>
      <IconWrapper>
        <Icon icon={icon} size={size} />
      </IconWrapper>
      <AnimatePresence>
        {active && (
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {text}
          </motion.span>
        )}
      </AnimatePresence>
    </Button>
  );
};

export default NavigationButton;
