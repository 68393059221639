import React, { useState, useContext } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/core";

import IconButton from "../Widgets/IconButton";
import NavigationButton from "./NavigationButton";

import { GlobalContext } from "../../context/global";

const NavigationWrapper = styled.div([
  tw`absolute z-40 top-0 left-0 bg-white shadow-lg h-full text-red-600 border-gray-500 border-r font-semibold `,
  css`
    background: rgba(255, 255, 255, 0.9);
    overflow:hidden;
  `,
]);
const ToggleWrapper = styled.div([
    tw`p-5 flex justify-start`,
    css`
    width:100%;
    top:0;
    left:0;
    position:absolute;
      background: #ebebeb;
  `,
]);

const SCROLLABLE = styled.div([
    css`
    overflow-y:scroll;
    height:100%;
    display:block;
    padding-top:90px;
  `,
]);
const Ul = tw.ul`py-5 border-gray-500 border-b border-t`;
const Li = tw.li`px-5 py-6`;
const Heading = tw.li`px-5 font-bold `;

const Navigation: React.FC = () => {
  const [active, setActive] = useState<boolean>(false);

    const {
        state: {
            user: {authorised},
            /* eslint-disable @typescript-eslint/no-unused-vars */
            app: {ui},
        },
        dispatch,
    } = useContext(GlobalContext);

  const changeScene = (newScene: string) => {
    if (active) {
      toggleNavigation();
    }
    dispatch({
      type: "APP_UPDATE_SCENE",
      scene: newScene,
    });
  };

  const toggleNavigation = () => {
    setActive(!active);
  };

  const toggleComponent = (component: string) => {
    dispatch({
      type: "APP_TOGGLE_UI_COMPONENT",
      component: component,
    });
  };

  return (
    <NavigationWrapper>
      {authorised && (
        <>
          <motion.div
            animate={{
              width: active ? "320px" : "auto",
              height: "100%",
            }}
            transition={{ ease: "easeOut" }}
          >
            <ToggleWrapper>
              <IconButton
                icon={active ? "arrow-left" : "arrow-right"}
                size="large"
                clickEvent={toggleNavigation}
              />
            </ToggleWrapper>
            <SCROLLABLE>
              <Ul>
                {active && <Heading>Locations</Heading>}
                <Li>
                  <NavigationButton
                    active={active}
                    icon="concierge-bell"
                    size="large"
                    text="Lobby"
                    clickEvent={() => {
                      toggleComponent(''); 
                      changeScene("atrium");
                    }}
                  />
                </Li>
                <Li>
                  <NavigationButton
                    active={active}
                    icon="id-card-alt"
                    size="large"
                    text="Exhibition Hall"
                    clickEvent={() => {
                      toggleComponent(''); 
                      changeScene("booth")
                    }}
                  />
                </Li>
                <Li>
                  <NavigationButton
                    active={active}
                    icon="microphone"
                    size="large"
                    text="Main Congress Room"
                    clickEvent={() => {
                      toggleComponent('');
                      changeScene("symposium");
                    }}
                  />
                </Li>
              </Ul>
              <Ul>
                {active && <Heading>Resources</Heading>}
                <Li>
                  <NavigationButton
                    active={active}
                    icon="users"
                    size="large"
                    text="Exhibition Chat"
                    clickEvent={() => toggleComponent("chat")}
                  />
                </Li>
                <Li>
                  <NavigationButton
                    active={active}
                    icon="video"
                    size="large"
                    text="Video Call"
                    clickEvent={() => toggleComponent("meeting")}
                  />
                </Li>
                <Li>
                  <NavigationButton
                    active={active}
                    icon="robot"
                    size="large"
                    text="Chatbot"
                    clickEvent={() => toggleComponent("chatbot")}
                  />
                </Li>
                <Li>
                  <NavigationButton
                    active={active}
                    icon="file-download"
                    size="large"
                    text="Downloads"
                    clickEvent={() => toggleComponent("downloads")}
                  />
                </Li>
                <Li>
                  <NavigationButton
                    active={active}
                    icon="calendar-alt"
                    size="large"
                    text="Agenda"
                    clickEvent={() => toggleComponent("agenda")}
                  />
                </Li>
              </Ul>
            </SCROLLABLE>
          </motion.div>
          {/*<User active={active} />*/}
        </>
      )}
    </NavigationWrapper>
  );
};

export default Navigation;
