import { VideoRoom } from '../@types/types';

export interface SocketState {
  socket: SocketIOClient.Socket;
  congressRoom: string;
  rooms: VideoRoom[];
  videoRoom: string | null;
  currentVideoRoom?: string | null;
  token: string | null;
}

export interface SocketAction extends SocketState {
  type: string;
  room: string;
  videoData: {
    videoRoom: string;
    token: string;
  }
}

export const initialState: SocketState = {
  socket: null,
  congressRoom: 'congress',
  rooms: [],
  videoRoom: null,
  token: null,
};

export const reducer = function (state: SocketState, action: SocketAction): SocketState {
  switch (action.type) {
    case 'SOCKET_CONNECTED':
      return {
        ...state,
        socket: action.socket,
      };
    case 'SOCKET_ADD_ROOM':
      return {
        ...state,
        rooms: action.rooms,
      };
    case 'SOCKET_UPDATE_ROOMS':
        return {
          ...state,
          rooms: action.rooms,
        };
    case 'SOCKET_CURRENT_ROOM':
      return {
        ...state,
        currentVideoRoom: action.room,
      };
    case 'SOCKET_UPDATE_TOKEN':
      return {
        ...state,
        videoRoom: action.videoData.videoRoom,
        token: action.videoData.token,
      };
    default:
      return state;
  }
};