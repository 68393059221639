import React from "react";
import tw from "twin.macro";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/core";
import isMobile from "../../utils/mobileDetector";
import Icon from "./Icon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Button = styled.div([
  tw`h-12 px-3 bg-red-600 border-red-600 border text-white rounded shadow flex justify-center items-center hover:bg-white hover:text-red-600 hover:cursor-pointer`,
  css`
    ${isMobile
      ? `height: 2.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    .icon-wrapper {
      font-size: 1rem;
    }
    `
      : ``}
  `,
]);

type ButtonIconProps = {
  clickEvent?: () => void;
  icon: IconProp;
  size?: string;
  title?: string;
};

const IconButton: React.FC<ButtonIconProps> = ({
  clickEvent,
  icon,
  size,
  title,
}) => {
  return (
    <Button onClick={clickEvent} title={title}>
      <Icon icon={icon} size={size} />
    </Button>
  );
};

export default IconButton;
