import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faCircleNotch,
  faConciergeBell,
  faFileDownload,
  faIdCardAlt,
  faMicrophone,
  faRobot,
  faTimes,
  faUniversity,
  faUsers,
  faVideo,
  faWindowClose,
  faUser,
  faExclamationCircle,
  faExpandAlt,
  faCompressAlt,
  faAngleDown,
  faDownload,
  faArrowRight,
  faArrowLeft,
  faMobileAlt,
  faCalendarAlt,
  faPlay,
  faPause,
  faStepForward,
  faStepBackward,
} from "@fortawesome/free-solid-svg-icons";

export const fontLibrary = () => {
  library.add(
    faBars,
    faCircleNotch,
    faConciergeBell,
    faFileDownload,
    faIdCardAlt,
    faMicrophone,
    faRobot,
    faTimes,
    faUniversity,
    faUsers,
    faVideo,
    faWindowClose,
    faUser,
    faExclamationCircle,
    faExpandAlt,
    faCompressAlt,
    faAngleDown,
    faDownload,
    faArrowRight,
    faArrowLeft,
    faMobileAlt,
    faCalendarAlt,
    faPlay,
    faPause,
    faStepForward,
    faStepBackward
  );
};
