import { ChatMessage } from '../@types/types';

export interface ChatState {
  messages: ChatMessage[];
  users: any[]; // TYPE ME
}

export interface ChatAction extends ChatState {
  type: string;
}

export const initialState: ChatState = {
  messages: [],
  users: [],
};

export const reducer = function (state: ChatState, action: ChatAction): ChatState {
  switch (action.type) {
    case 'CHAT_ADD_MESSAGE':
      return {
        ...state,
        messages: action.messages,
      };
    case 'CHAT_UPDATE_USERLIST':
      return {
        ...state,
        users: action.users,
      };
    case 'CHAT_CLEAR_MESSAGE':
      return {
        ...state,
        messages: [],
      };
    default:
      return state;
  }
};