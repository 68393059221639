import React from "react";
import tw from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Btn = tw.button`hidden w-48 text-white bg-red-600 shadow pt-3 px-3 rounded text-sm font-bold uppercase hover:cursor-pointer md:block`;
const Text = tw.span`block`;
const IconWrap = tw.span`w-full text-center text-lg leading-tight`;

type ButtonProps = {
  clickEvent?: () => void;
  text: string;
};

const FullScreenBtn: React.FC<ButtonProps> = ({ text, clickEvent }) => (
  <Btn onClick={clickEvent}>
    <Text>{text}</Text>
    <IconWrap>
      <FontAwesomeIcon icon="angle-down" />
    </IconWrap>
  </Btn>
);

export default FullScreenBtn;
