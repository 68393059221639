import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tw from "twin.macro";
import styled from "@emotion/styled/macro";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  icon: IconProp;
  size: string;
}

const Icon: React.FC<Props> = ({ icon, size }) => {
  const IconWrapper = styled.div([
    tw`w-6 flex justify-center items-center`,
    size === "large"
      ? tw`text-xl`
      : size === "small"
      ? tw`text-sm`
      : tw`text-lg`,
  ]);

  return (
    <IconWrapper className="icon-wrapper">
      <FontAwesomeIcon icon={icon} />
    </IconWrapper>
  );
};

export default Icon;
