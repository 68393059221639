import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GlobalContext } from "../../context/global";

const NavToggle = styled.div([
  tw`fixed bg-red-600 rounded-full w-12 h-12 p-2 text-white text-center leading-8`,
  css`
    top: 20px;
    left: 10px;
  `,
]);

const Item = tw.div`rounded w-12 h-12 p-2 bg-white text-red-600 text-center leading-8 mb-3`;

const NavWrap = styled.div([
  css`
    z-index: 40;
    position: absolute;
    top: 80px;
    left: 0;
  `,
]);

const HR = tw.hr`border-red-600 border mb-3`;

const MobileNav: React.FC = () => {
  const [active, setActive] = useState<boolean>(false);
  const [winHeight, setWinHeight] = useState(0);

  useEffect(() => {
    getHeight();
  });

  const {
    state: {
      user: { authorised },
    },
    dispatch,
  } = useContext(GlobalContext);

  const getHeight = () => {
    const w = window.innerWidth;
    const h = window.innerHeight;
    if (w > h) {
      setWinHeight(h);
    } else {
      setWinHeight(w);
    }
  };

  const toggleNavigation = () => {
    setActive(!active);
  };

  const changeScene = (newScene: string) => {
    if (active) {
      toggleNavigation();
    }
    dispatch({
      type: "APP_UPDATE_SCENE",
      scene: newScene,
    });
  };

  const toggleComponent = (component: string) => {
    dispatch({
      type: "APP_TOGGLE_UI_COMPONENT",
      component: component,
    });
  };

  return (
    <NavWrap>
      {authorised && (
        <>
          <NavToggle onTouchEnd={toggleNavigation}>
            <FontAwesomeIcon icon={active ? "times" : "bars"} />
          </NavToggle>
          <motion.div
            style={{
              height: `${winHeight - 80}px`,
              width: "3rem",
              overflow: "scroll",
            }}
            initial={{ x: -50 }}
            animate={{
              x: active ? 10 : -50,
            }}
            transition={{ ease: "easeOut" }}
          >
            <Item onClick={() => changeScene("atrium")}>
              <FontAwesomeIcon icon="concierge-bell" />
            </Item>
            <Item onClick={() => changeScene("booth")}>
              <FontAwesomeIcon icon="id-card-alt" />
            </Item>
            <Item onClick={() => changeScene("symposium")}>
              <FontAwesomeIcon icon="microphone" />
            </Item>
            <HR />
            <Item onClick={() => toggleComponent("chat")}>
              <FontAwesomeIcon icon="users" />
            </Item>
            <Item onClick={() => toggleComponent("meeting")}>
              <FontAwesomeIcon icon="video" />
            </Item>
            <Item onClick={() => toggleComponent("chatbot")}>
              <FontAwesomeIcon icon="robot" />
            </Item>
            <Item onClick={() => toggleComponent("downloads")}>
              <FontAwesomeIcon icon="file-download" />
            </Item>
            <Item onClick={() => toggleComponent("agenda")}>
              <FontAwesomeIcon icon="calendar-alt" />
            </Item>
          </motion.div>
        </>
      )}
    </NavWrap>
  );
};

export default MobileNav;
